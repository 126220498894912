import { faCaretSquareRight, faHome, faHouseDamage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import StepMotion from './blocks/stepMotion';

const KonnanSouzoku = () => {
  return (
    <section className="text-gray-500 body-font text-lg">
        <div className="container px-5 py-3 mx-auto flex flex-wrap">
            <div className="flex flex-wrap w-full">
                <h1 className='m-2 mb-5'>
                    <FontAwesomeIcon icon={faCaretSquareRight} className='mr-2 text-rose-500 text-xl' />
                    A所有の不動産に、A、配偶者B、子のCの３人が居住していた事例で、全ての相続で遺言書などはありませんでした。
                </h1>
                <div className="md:pr-10 md:py-6">
                    <StepMotion title={`① A死亡`} desc={`Aが死亡し、相続人は配偶者Bと子のC`} icon={faHome} iconColor={`bg-emerald-500`} />
                    <StepMotion title={`② Bが子のいないDと再婚`} desc={`この時点で、子Cは成人し、家を出ていたため、Dとの養子縁組はしなかった。`} icon={faHome} iconColor={`bg-emerald-300`} />
                    <StepMotion title={`③ B死亡`} desc={`Bの相続人は、Dと子のC。`} icon={faHome} iconColor={`bg-yellow-300`} />
                    <StepMotion title={`④ D死亡`} desc={`Dは、子がなく、両親等の直系尊属も他界しているため、Dの相続については、Dの兄弟姉妹が相続人。`} icon={faHouseDamage} iconColor={`bg-red-400`} />
                    <StepMotion title={`⑤ A名義不動産の相続`} desc={`Cは、使用しなくなったA名義の不動産を売却しようとしたところ、相続登記をしなければならないと司法書士に言われ、相続人を調査したところ、相続人は自分以外に、Dの相続人であるDの兄弟姉妹が３名で、既に２名が他界しており、１名は認知症であったことが判明。また、他界した２名の代襲相続人として、Dの甥、姪が海外や、遠方に数名存在していることも判明した。`} icon={faHouseDamage} iconColor={`bg-red-600`} />
                </div>
                
            </div>
        </div>
    </section>
  );
};

export default KonnanSouzoku;
