import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';

const StepMotion = ({title, desc, icon, iconColor}) => {
  return (
    <motion.div initial={{opacity: 0}} whileInView={{ opacity:[0, 0, 0.5, 1] }} transition={{duration: 0.6}} viewport={{once: true}} className="flex relative pb-8">
        <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
        </div>
        <div className={`flex-shrink-0 w-10 h-10 rounded-full ${iconColor} inline-flex items-center justify-center text-white relative z-10`}>
            <FontAwesomeIcon icon={icon} />
        </div>
        <div className="flex-grow pl-4">
            <h2 className="font-medium text-gray-600 mb-1 tracking-wider text-xl">{title}</h2>
            <p className="leading-relaxed">{desc}</p>
        </div>
    </motion.div>
  );
};

export default StepMotion;
