import Layout from '@/components/Layout';
import ArticleTemp from '@/components/modules/articleTemp';
import React from 'react';
import KonnanSouzoku from '@/components/konnanSouzoku';
import KonnanSouzokuGuide from '@/components/konnanSouzokuGuide';
import SEO from '@/components/seo';

const Difficult = () => {
  return (
      <Layout>
        <SEO title={`相続困難事例`} description={`相続を長期間放置していると、手続きが困難になってしまう事例のご紹介です。相続手続きは速やかに行いましょう。`} />
        <ArticleTemp header={`相続登記が困難になる事例`} descText={`相続登記を長年放置してしまうと、不動産が負動産になってしまう数次相続と言われる実際にあった事例を簡略化してご紹介します。`}>
          <KonnanSouzoku/> 
          <KonnanSouzokuGuide/>
        </ArticleTemp>
      </Layout>
  );
};

export default Difficult;
