import { faHandPointDown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DescStepMotion from './blocks/descStepMotion';

const KonnanSouzokuGuide = () => {
  return (
    <section className="text-gray-500 body-font">
        <div className="container px-5 py-5 mx-auto">
            <div className="flex flex-col text-center w-full mb-5">
                <h1 className="text-2xl font-medium text-red-500 mb-6 border pb-2 bg-rose-100 shadow-md p-2 shadow-neutral-300">
                    <FontAwesomeIcon icon={faHandPointDown} className='mr-2 text-blue-500 text-2xl' />
                    上記事例の結果
                    <FontAwesomeIcon icon={faHandPointDown} className='ml-2 text-blue-500 text-2xl' />
                </h1>
                <p className="mx-auto text-left text-lg">上記は、長期間に渡って相続登記を放置した結果、いざ売却する段階になって、３名の相続手続きをまとめてやらなければならなくなったことにより生じた問題です。そうなると、人間関係が希薄となる人と遺産相続の話し合いをしなければならなくなり、また、連絡先が分からず、その調査に時間と費用を割かなければなりません。</p>
            </div>
            <div>
                <DescStepMotion title={`① Aの死亡`} desc={`この時点で、相続人は配偶者Bと子のCであったため、法定相続人はBとCで、法定相続分はそれぞれ２分の１ずつとなります。また、親子のBとCの遺産分割協議によりBまたはCのいずれかの単独所有の不動産とすることもできました。`} />
                <DescStepMotion title={`② Bが子のいないDと再婚`} desc={`再婚したことにより、推定相続人が変更されることになりました。Bの推定相続人として、Cの他にDが加わることになります。この時にAの相続手続きを済ませておくべきでした。`} />
                <DescStepMotion title={`③ B死亡`} desc={`上述の通り、Bの相続人は配偶者Dと、子のCとなり、法定相続分はそれぞれ２分の１となります。A名義の不動産を法定相続したとすると、２分の１亡B・２分の１Cの共有から、４分の３C・４分の１Dの共有へと変更となります。また、この時点で、A名義の不動産をCの単独名義に相続するには、CとDで遺産分割協議をする必要がありますが、Dは、亡Bの遺産分割協議に参加する地位を承継した者として扱われることとなります。`} />
                <DescStepMotion title={`④ D死亡`} desc={`Dの有していた、A名義の不動産に関する潜在的な相続分４分の１をDの相続人が承継することとなります。ここで、A名義の不動産をC名義に変更するには、Cは見知らぬDの相続人たちと遺産分割協議をしなければならない事になりました。その相続人が国内にいる場合は、住所は比較的簡単に調査できるので、手紙を送るなどすれば連絡を取ることはまだ可能ですが、国外の場合はそうはいきません。住民票や、戸籍の附票には転出先の国名の記載があるだけなので、現地大使館などで調査をすることになってしまいます。また、成年後見人の選任が必要な相続人がいた場合、遺産分割協議のためにその選任を家庭裁判所へ申立てをしなければなりません。人間関係が希薄な状態で、そのお願いを聞いてもらえれば良いですが、各家庭の事情等により上手く手続きを進められるかどうかは不透明な状態となることが予想されます。`} />
                <DescStepMotion title={`⑤ A名義不動産の相続`} desc={`Cは、上記の問題を長期に渡り少しずつ解決した結果、なんとか相続登記をし、不動産を売却できたものの、売却代金のほとんどが相続手続き費用に消えてしまいました。`} />
                <DescStepMotion title={`⑥ 総括`} desc={`この事例では、売却できたこと、手続き費用が売却代金で賄えたことから成功事例だと考えられます。しかしながら、市場価値の低い不動産の場合、売却代金の何倍もの手続き費用が必要となることあります。また、手続きを進めたものの、問題をクリアできずに、手続き費用だけ負担しなければならないこともあります。こうした事情が、長期間相続登記未了問題の一端ともなっています。こういった事態にならないためにも、なるべく早い相続対応が必要となります。`} />
            </div>
        </div>
    </section>
  );
};

export default KonnanSouzokuGuide;
