import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';

const DescStepMotion = ({ title, desc }) => {
  return (
    <motion.div initial={{opacity: 0}} whileInView={{ opacity:[0, 0, 0.5, 1] }} transition={{duration: 0.6}} viewport={{once: true}} className="md:w-full px-5 py-3 border-l-2 border-gray-200 border-opacity-60 text-lg">
        <h2 className="font-medium text-gray-600 mb-2 text-xl">
            { title }
            <FontAwesomeIcon icon={faKey} className='ml-2 text-xl text-amber-400' />
        </h2>
        <p className="mb-4">{desc}</p>
    </motion.div>
  );
};

export default DescStepMotion;
